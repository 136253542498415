<template>
  <div id="termsofuse">
    <div class="top__header">
      서비스이용약관
    </div>
    <div class="flex">
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제1조 [목적]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'first' }"></div>
          <div class="text" v-show="showtext === 'first'">
            본 약관은 남해군(이하 "남해군"이라 한다)과 남해군으로부터 운영위탁을 받은 ㈜유니드(“위탁업체”)가 운영하는 남해군 온라인 관광플랫폼(이하 “낭만남해”라 한다)에서 제공하는 서비스를 이용자와 이용조건 및 권리‧의무와 절차 및 책임사항 등을 규정하는 것을 목적으로 한다.
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('second')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제2조 [정의]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'second' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'second' }"></div>
          <div class="text" v-show="showtext === 'second'">
            본 약관에서 사용하는 용어의 정의는 다음과 같다. 그리고 여기에 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 따른다.<br>
            ① “낭만남해”란 “이용자”가 컴퓨터, 모바일(앱 포함) 등 정보통신설비를 이용하여 “서비스”를 이용할 수 있도록 “남해군”이 제공하는 가상의 영업장 또는 플랫폼을 말한다. 또한 남해군과 위탁업체를 아우르는 운영자의 의미도 포함한다.<br>
            ② “이용자”란 낭만남해를 통해 이 약관에 따라 제공하는 서비스를 받는 회원 및 비회원을 말한다.<br>
            ③ “서비스”란 회원이 가맹점과 상품 또는 용역 등을 거래함에 있어서 남해군이 낭만남해 내 제공하는 예약 및 결제서비스, 쿠폰 및 포인트 서비스, 인증 서비스, 기타 연계 서비스 등 이용자에게 제공하는 서비스를 총칭하며, 정보통신설비(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함하여 이에 한정되지 않음)과 상관없이 낭만남해 웹(Web), 앱(App) 등의 제반 서비스를 포함한다. <br>
            ④ “입점업체”란 이 약관을 승인하고 낭만남해에서 제공하는 서비스에 입점 가입을 신청하여 승낙 받은 업소 또는 시설을 말한다.<br>
            ⑤ “회원”이란 낭만남해를 통하여 이 약관에 동의하고 가입절차에 따라 회원등록을 한 자로서, 계속적으로 “플랫폼”이 제공하는 서비스를 이용할 수 있는 자를 말한다.<br>
            ⑥ “비회원”이란 회원에 가입하지 않고 "플랫폼"이 제공하는 서비스를 이용하는 자를 말한다.<br>
            ⑥ “아이디(ID)”란 회원의 식별과 회원의 서비스 이용을 위하여 회원이 설정하고 플랫폼이 승인하는 영문과 숫자의 조합을 말한다.<br>
            ⑦ “비밀번호(PW)”란, 회원의 식별 및 비밀을 보호하기 위하여 회원 스스로가 설정한 영문과 숫자의 조합을 말한다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('third')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 3조 [약관의 명시와 개정]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'third' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'third' }"></div>
          <div class="text" v-show="showtext === 'third'">
            ① 본 약관은 낭만남해를 통하여 게시하며, 게시함으로써 그 효력이 발생한다.<br>
            ② 낭만남해는 약관의 규제 등에 관한 법률, 정보통신망 이용촉진 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있으며, 이 경우 개정내용, 적용일자 및 개정사유를 명시하여, 현행약관과 함께 적용일자 최소 7일 이전부터 공지한다. 다만, "이용자"에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지한다. 이 경우 낭만남해는 개정 전 내용과 개정 후 내용을 "이용자"가 알기 쉽도록 표시한다. <br>
            ③ 낭만남해는 서비스 이용과 관련하여 별도의 이용정책 등을 운영할 수 있으며, 해당 운영정책 등은 이 약관의 일부를 구성한다.<br>
            ④ 이용자는 신설 또는 변경된 약관사항에 동의하지 않으면, 회원탈퇴를 통해 서비스 이용을 중단할 수 있다. 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 등록자가 동의한 것으로 간주된다.<br>
            ⑤ 이용자는 약관 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 이용자의 피해에 대하여는 낭만남해의 귀책사유가 없는 한 이를 책임지지 않는다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('fourth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 4조 [약관 외 준칙]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fourth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'fourth' }"></div>
          <div class="text" v-show="showtext === 'fourth'">
            ① 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않은 경우에는 일반적인 관례에 따른다.<br>
            ② 본 약관에서 정한 운영자의 책임 제한 규정은 관련 법령이 허용하는 최대한도의 범위 내에서 적용된다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('fifth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 5조 [대리 및 보증의 부인]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fifth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'fifth' }"></div>
          <div class="text" v-show="showtext === 'fifth'">
            ① 낭만남해는 구매자(이용자)와 판매자(입점업체) 간의 자유로운 상품의 거래 및 정보교환을 위한 시스템을 운영 및 관리, 제공할 뿐이며, 구매자와 판매자를 대리하지 않는다.<br>
            ② 회원 사이에 성립된 거래와 관련된 책임과 회원이 제공한 정보에 대한 책임은 해당 회원이 직접 부담하여야 한다.<br>
            ③ 낭만남해는 낭만남해를 통하여 이루어지는 구매자와 판매자 간의 거래와 관련하여 판매 의사 또는 구매의사의 존부 및 진정성, 등록 상품의 본질, 완전성, 안전성, 적법성, 타인의 권리에 대한 비침해성, 구매자 또는 판매자가 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체를 보증하지 않는다. 다만, 등록된 상품 관련 정보가 법규 위반이나 저작권 침해가 명백한 경우, 또는 저작권 등 권리를 침해받았다고 주장하는 자가 그 침해의 정지‧예방을 요청하는 경우 낭만남해는 당사자 간의 분쟁이 해결되어 권리관계가 명확해질 때까지 해당 상품에 대한 삭제, 수정 또는 미노출 처리할 수 있다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('sixth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 6조 [개인정보보호에 관한 사항]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'sixth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'sixth' }"></div>
          <div class="text" v-show="showtext === 'sixth'">
            ① 이용자의 정보수집 시 이용에 필요한 최소한의 정보를 수집하며, 이용자의 개인정보를 수집 및 이용하는 때에는 이용자에게 그 목적을 고지하고 동의를 받는다.<br>
            ② 수집된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으나, 다음의 경우에는 예외로 한다.<br>
            1. 상품예약 특성상 입점업체(판매자)에게 필요한 최소한의 정보(이름, 연락처)를 알려주는 경우<br>
            2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
            3. 이벤트‧프로모션 등 당첨자 안내, 상품(경품) 발송, 사후관리 등에 필요한 경우<br>
            ③ 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용‧제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받는다. 다만, 관련 법령에 정함이 있는 경우 예외로 한다.<br>
            ④ 낭만남해는 개인정보 보호법 등 관계법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력한다. 개인정보 보호 및 사용에 대해서는 관련법령 및 낭만남해의 개인정보처리방침이 적용된다. 다만, 낭만남해 이외의 링크된 사이트는 낭만남해의 개인정보처리방침이 적용되지 않는다.<br>
            ⑤ 낭만남해는 이용자가 제공한 개인정보를 제공자의 동의없이 본 약관에 정한 목적 이외의 용도로 이용하거나, 서비스 수행 목적 범위를 넘어서서 임의의 목적으로 제3자에게 제공하지 않는다.<br>
            ⑥ 개인정보보호에 관한 사항은 낭만남해에 게시된 개인정보처리방침에 규정된 내용에 따른다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('seventh')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 7조 [회원가입]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'seventh' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'seventh' }"></div>
          <div class="text" v-show="showtext === 'seventh'">
            ① 이용자는 낭만남해가 정한 가입양식과 절차에 따라 이 약관과 개인정보처리방침에 동의한다는 의사표시를 함으로써 회원가입을 신청한다.<br>
            ② 낭만남해는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록한다. 만일, 다음 각 호에 해당하는 경우 회원가입신청에 대하여 승인하지 않거나 사후에 이용계약을 해지할 수 있다.<br>
            1. 회원가입 시 등록 내용에 허위, 기재누락, 오기가 있는 경우<br>
            2. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우<br>
            3. 이용자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br>
              (낭만남해의 사전 재가입 승낙을 얻은 경우는 예외로 함)<br>
            4. 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우<br>
            5. 기타 본 약관에 위배되거나 위법 또는 부당한 이용 신청임이 확인된 경우<br>
            6. 만 14세 미만의 아동이 신청할 경우 <br>
            ③ 제1항에 따른 신청에 있어 낭만남해는 전문기관을 통한 휴대전화번호 점유인증을 통하여 인증을 요청할 수 있다.<br>
            ④ 낭만남해는 아래 각 호에 해당하는 경우에는 회원 등록의 승인을 유보할 수 있다.<br>
            1. 제공 서비스 설비용량에 현실적인 여유가 없는 경우<br>
            2. 서비스를 제공하기에 기술적으로 분제가 있다고 판단되는 경우<br>
            3. 제3항에 따른 인증절차를 진행 중인 경우<br>
            ④ 회원가입계약의 성립 시기는 낭만남해의 승낙이 수신확인 통지 형태로 이용자에게 도달한 시점으로 본다.<br>
            ⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 낭만남해는 승낙 거절 또는 유보 사실을 가입신청자에게 내부 정하는 방법에 따라 통지한다.<br>
            ⑥ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 등의 절차를 통하여 웹사이트에 그 변경 사항을 알려야 하며, 회원이 이를 알리지 않거나 지연시킴으로 인해 발생한 회원의 불이익에 대하여 낭만남해는 책임지지 않는다.<br><br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('eighth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 8조 [ID 및 비밀번호의 관리책임]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'eighth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'eighth' }"></div>
          <div class="text" v-show="showtext === 'eighth'">
            ① 회원의 ID 및 비밀번호에 대한 관리책임은 회원에게 있으며, 이를 제3자에게 양도 내지 대여할 수 없다.<br>
            ② 낭만남해는 운영자의 귀책사유로 인한 경우를 제외하고 계정 정보의 유출, 양도, 대여, 공유 등으로 인한 손실이나 손해에 대하여 책임을 지지 않는다.<br>
            ③ 낭만남해는 회원의 ID가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 운영자로 오인할 우려가 있는 경우, 해당 ID의 이용을 제한할 수 있다.<br>
            ④ 회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 도용하고 있음을 인지한 경우, 즉시 이를 낭만남해에 통보하고 낭만남해의 안내가 있는 경우 그에 따라야 한다. 본항에 따른 통지를 하지 아니하여 발생하는 모든 불이익에 대한 책임은 회원에게 있다.<br>
            ⑥ 개인정보보호에 관한 사항은 낭만남해에 게시된 개인정보처리방침에 규정된 내용에 따른다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('ninth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 9조 [회원탈퇴 및 자격상실 등]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'ninth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'ninth' }"></div>
          <div class="text" v-show="showtext === 'ninth'">
            ① 회원은 언제든지 회원탈퇴를 요청할 수 있으며, 회원의 탈퇴요청이 있을 시에 낭만남해는 서비스 제공을 중지하고, 기타 서비스 이용 해지에 따른 제반절차를 완료한 뒤에 해당 회원을 탈퇴 처리한다. 이 경우 제공된 쿠폰은 모두 소멸한다.<br>
            ② 회원이 다음 각 호의 사유에 해당하는 경우, 회원자격을 제한 및 정지시킬 수 있다.<br>
            1. 가입 신청 시 허위 내용을 작성한 경우<br>
            2. 회원 기본정보가 변경되어 회원과 연락이 불가한 경우<br>
            3. 같은 사용자가 다른 연락처로 이중 등록을 한 경우<br>
            4. 타인의 이용자 연락처 및 비밀번호를 도용한 경우<br>
            5. 공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우<br>
            6. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우<br>
            7. 컴퓨터 바이러스 유포 등 서비스 운영을 고의로 방해한 경우<br>
            8. 만14세 미만의 아동이 신청할 경우<br>
            9. 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br>
              (단, 사전 재가입 승낙을 얻은 경우에는 예외로 함)<br>
            10. 기타 관련 법령과 일반적인 상행위에 위반된다고 “플랫폼”이 판단되는 경우<br>
            ③ 낭만남해는 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회원자격을 상실시킬 수 있다.<br>
            ④ 회원이 탈퇴 및 자격상실한 경우, 관련법령 및 개인정보처리방침에 따라 낭만남해가 회원 정보를 보유할 수 있는 경우를 제외하고 회원의 개인정보는 즉시 삭제한다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('tenth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 10조 [회원에 대한 통지]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'tenth' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'tenth' }"></div>
          <div class="text" v-show="showtext === 'tenth'">
            ① 낭만남해가 회원에 대한 통지를 하는 경우, 회원이 제공한 E-mail, SMS 등으로 할 수 있다.<br>
            ② 불특정다수 회원에 대한 통지의 경우 1주일 이상 낭만남해에 게시함으로서 개별 통지에 갈음할 수 있다. 다만 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지한다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num11')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 11조 [서비스 개요 및 종류]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num11' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num11' }"></div>
          <div class="text" v-show="showtext === 'num11'">
            ① 본 약관에 정해진 바에 따라 이용자에게 제공하는 서비스는 아래와 같다.<br>
            1. 관광 관련 상품, 콘텐츠에 대한 정보 제공서비스<br>
            2. 서비스 예약에 대한 중개 등 통신판매중개서비스<br>
            3. 판매 관련 업무지원 서비스<br>
            4. 입점 관련 업무지원 서비스<br>
            5. 구매 관련 지원 서비스<br>
            6. 계약체결, 결제지원 서비스<br>
            7. 프로모션 서비스 <br>
            8. 위치기반 서비스<br>
            9. 기타 전자상거래 관련 서비스<br>
            10. 기타 상품문의 게시판, 상품평 등 기타정보 제공, 직접 또는 공동으로 제공하는 이벤트 및 프로모션 서비스를 말한다.<br>
            ② 낭만남해는 상품의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품의 내용을 변경할 수 있다.<br>
            ③ 낭만남해가 제공하기로 한 이용자와 계약을 체결한 서비스의 내용을 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 제17조 이용자에 대한 통지에 따라 이용자에게 통지 가능한 방법으로 즉시 통지한다.<br>
            ④ 전항의 경우 이로 인하여 이용자가 입은 손해를 배상한다. 다만, 낭만남해의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니하다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num12')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 12조 [서비스 이용]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num12' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num12' }"></div>
          <div class="text" v-show="showtext === 'num12'">
            ① 이용자가 이 약관에 동의하고 낭만남해가 정한 인증절차를 완료하여 승인이 완료되면 영업상, 기술상 사정변경이 없는 한 서비스를 이용할 수 있다.<br>
            ② 구매자는 상품을 구매하기 전에 반드시 판매자가 사이트 내에 작성한 상품의 상세내용과 거래의 조건을 정확하게 확인해야 한다. 구매하려는 상품의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손실과 손해는 구매자 본인에게 있다.<br>
            ③ 구매자는 상품의 상세내용과 거래의 조건 등에 관한 내용을 판매자에게 확인하기 위하여 낭만남해에서 제공하는 상품문의(1:1) 서비스를 이용할 수 있다.<br>
            ④ 이용자는 구매의사 없이 판매자의 상품에 악의적인 판매 방해행위(구매-취소 반복행위, 허위예약 등)를 통하여 상품 가격을 올리거나 구매의사를 표시하여 판매자의 상품판매 기회를 방해하거나 다른 구매자의 구매 기회를 방해한 것이 적발되면 운영자는 해당 이메일 및 해당 이메일과 동일인 소유로 확인되는 이메일을 포함하여, 이에 대하여 회원자격을 정지하는 등의 조치를 할 수 있다.<br>
            ⑤ 구매자는 본 약관과 낭만남해 서비스 화면에서 알리는 내용을 지켜야 하며, 약관과 알리는 내용을 위반하거나 이행하지 않아서 발생하는 모든 손실과 손해에 대해 책임을 진다.<br>
            ⑥ 낭만남해는 판매자가 등록한 상품의 내용과 거래 조건에 대해서 어떠한 보증이나 대리를 하지 않는다. 따라서 구매자는 스스로 책임지고 상품을 구매해야 한다.<br>
            ⑦ 구매자는 판매자와 상품 매매 절차에서 분쟁이 발생하면 분쟁의 해결을 위하여 성실히 임해야 하며, 분쟁 해결 과정에서의 불성실 등 구매자의 귀책사유로 판매자와 운영자에 손실과 손해가 발생될 경우 그에 대한 모든 책임은 구매자에게 있다.<br>
            ⑧ 구매자가 상품을 구매할 때는 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을 임의로 사용해서는 안된다. 타인의 결제수단을 임의로 사용하여 발생하는 운영자, 결제수단 소유자, 판매자의 손실과 손해에 대한 모든 책임은 구매자에게 있다.<br>
            ⑨ 매매 대금의 결제와 관련하여 구매자가 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 구매자가 부담하여야 한다.<br>
            ⑩ 낭만남해는 구매자가 매매 대금 결제때 사용한 결제수단에 대해 정당한 사용 권한이 있는지 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있다.<br>
            ⑪ 낭만남해는 구매자에게 서비스가 안전하게 제공될 수 있도록 각종 설비와 자료를 관리해야 하며, 서비스가 제공 목적에 맞게 이용되고 있는지 확인하고 구매자에게 이용 목적에 위반되는 부분이 있는 것으로 확인되면 사유의 소명을 요청할 수 있다.<br>
            ⑫ 낭만남해는 구매자의 서비스 이용 편의를 높이기 위해 가맹점으로부터 정보를 제공받아 게재하거나 제3자가 제공하는 방식으로 “낭만남해” 내 또는 링크 방식으로 참조용 정보나 콘텐츠를 제공할 수 있으며, 구매자는 상품 구매시 자신의 판단과 책임으로 결정하여 구매하여야 한다.<br>
            ⑬ 다운로드하여 설치한 앱 또는 네트워크를 통해 이용하는 서비스의 경우에는 모바일 기기 또는 이동통신사의 특성에 맞도록 제공된다. 모바일 기기 또는 번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이 불가능할 수 있으며, 낭만남해는 고의 또는 중대한 과실이 없는 경우 이에 대하여 책임지지 않는다.<br>
            ⑭ 만 14세 미만의 미성년자는 회원가입이 불가하며, 낭만남해에서 판매되는 모든 상품의 구매가 불가능하다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num13')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 13조 [서비스 부당이용]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num13' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num13' }"></div>
          <div class="text" v-show="showtext === 'num13'">
            ① 서비스 부당이용이란 회원 본인이 구매자나 여행자가 아님에도, 제휴카드를 통한 요금의 대납, 기타 다른 방법을 통해 할인 등 혜택을 이용하는 경우를 말한다. 단, 회원과 구매자나 여행자의 관계가 직계존비속 및 배우자임을 소명한 경우는 제외한다.<br>
            ② 위 1항에도 불구하고 입점업체 혹은 여행알선 종사자의 경우는 본인이 해당 상품의 여행자가 아님에도 결제하여 고객 혜택을 이용하는 경우 부당한 이용으로 간주한다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num14')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 14조 [서비스 제공의 변경 및 중지]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num14' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num14' }"></div>
          <div class="text" v-show="showtext === 'num14'">
            ① 낭만남해는 제공하는 서비스의 내용과 종류를 변경할 수 있다.<br>
            ② 낭만남해는 아래 각 호에 해당하는 경우 서비스의 일부 혹은 전부의 제공을 중지할 수 있다.<br>
            1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br>
            2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우<br>
            3. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때<br>
            4. 네트워크상의 위험성에 기초한 타인의 서비스 제공 음해 행위가 있는 때<br>
            5. 서비스의 제공을 위한 점검(수시, 정기)이 필요한 경우<br>
            ③ 1항과 2항에 의한 서비스 변경 및 중단의 경우 제17조(통지)에 정한 방법으로 이용자에게 통지한다. 단, 미리 예측할 수 없는 사정에 의한 서비스의 중단의 경우에는 예외로 한다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num15')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 15조 [서비스의 이용 제한]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num15' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num15' }"></div>
          <div class="text" v-show="showtext === 'num15'">
            ① 낭만남해는 이용자가 아래 각 호에 해당하는 경우 서비스의 이용을 제한할 수 있다.<br>
            1. 정보를 허위로 기재한 경우<br>
            2. 이 약관 및 관련된 별도의 서비스 준수 사항을 위반한 경우<br>
            3. 현행법령 및 사회통념에 반하는 행위를 하는 경우<br>
            4. 낭만남해의 영업을 방해하거나 제공하는 서비스와 동일한 업무를 행하는 경우<br>
            5. 구매의사 없이 판매자의 상품에 악의적인 판매 방해 행위(구매-취소 반복행위, 허위 예약 등)를 통하여 상품가격을 올리거나 구매의사를 표시하여 판매자의 상품 판매기회를 방해하거나 다른 구매자의 구매 기회를 방해한 경우<br>
            ② 1항에 따라 서비스 이용을 제한하기 위해 낭만남해는 해당 이메일 및 해당 이메일과 동일인 소유로 확인되는 이메일을 포함하여, 이에 대하여 회원자격을 정지하는 등의 조치를 할 수 있다.<br>
            ③ 회원이 계속해서 1년 이상 서비스를 이용하지 않은 경우에는, 개인정보 보호법에 따라 필요한 조치를 취할 수 있고, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있다.<br>
            ④ 본 조에 서비스 이용을 제한하거나 계약을 해지하는 경우에는 제17조(통지)에 정한 방법으로 회원에게 통지한다. <br>
            ⑤ 회원은 본 조에 따라 이용 제한에 대해 이의신청을 할 수 있으며, 낭만남해는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개한다. <br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num16')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 16조 [금지행위]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num16' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num16' }"></div>
          <div class="text" v-show="showtext === 'num16'">
            ① 낭만남해가 제공하는 결제 서비스를 이용하지 않고 판매자와 구매자가 직접 거래하는 행위(직거래)는 거래의 안전을 위하여 금지되고, 직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 낭만남해는 이에 대해 어떠한 책임도 지지 않는다.<br>
            ② 직거래를 하거나 이를 유도한 것으로 확인된 판매자는 서비스 이용이 정지되거나 이용계약이 해지될 수 있으며, 회원은 직거래를 하거나 유도한 판매자를 낭만남해에 신고할 수 있다.<br>
            ③ 낭만남해가 제공하는 서비스 이용방법을 따르지 않고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위는 금지된다.<br>
            ④ 시스템 부정행위가 확인되면 낭만남해는 해당 회원에게 부가적으로 제공한 혜택의 일부 또는 전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 할 수 있다.<br>
            ⑤ 타인의 명의, 카드 정보, 계좌 정보 등을 무단으로 사용하여 낭만남해가 제공하는 구매 서비스를 이용하는 행위는 금지된다.<br>
            ⑥ 물품 판매 또는 용역 제공을 가장하여 자금을 융통하는 것과 같이 여신 전문금융업법 등 법령에 따라 금지된 방법으로 비정상적인 결제를 하는 것은 금지되며, 해당 내용이 확인되었을 때 낭만남해는 회원의 거래를 취소하거나 중지시키고 관련 기관에 통보할 수 있다.<br>
            ⑦ 낭만남해가 제공하는 쿠폰 등의 유효기간을 연장하기 위한 목적으로 구매와 취소를 반복하는 등 상품의 실질적인 구매의사가 없는 구매행위와 낭만남해가 제공하는 할인율 등을 이용하여 비정상적인 거래를 하는 것은 금지되며, 해당 내용이 확인되면 낭만남해는 해당 거래를 취소하고 서비스 이용을 제한하거나 이용계약을 해지할 수 있다.<br>
            ⑧ 제3자에게 재판매하기 위한 목적으로 낭만남해가 제공하는 혜택이나 할인율 등을 부당하게 이용하는 행위(다른 오픈마켓이나 인터넷쇼핑몰 등에 임의로 최저가를 등록하는 등으로 “낭만남해”의 할인쿠폰 발행을 유도한 후 해당 쿠폰을 적용하여 구매하는 행위 등), 낭만남해에 손실을 발생시키는 행위는 금지되며, 재판매 목적의 거래 행위가 확인된 경우 해당 회원에 대하여 서비스 이용 제한, 이용계약의 해지 등의 조치를 취할 수 있으며, 이러한 행위로 손해가 있을 경우 배상을 청구할 수 있다.<br>
            ⑨ 판매자 본인의 상품 구매 또는 실제 상품의 이동 없이 판매 점수 및 광고 효과를 높이기 위한 구매 또는 부당한 할인 획득을 위해, 제3자의 이메일(E-Mail)을 이용한 구매 행위는 금지되며, 해당 내용이 확인된 경우 거래를 취소하고 서비스의 이용을 제한하는 등의 조치를 하거나 서비스의 이용을 제한하는 등의 조치를 할 수 있다.<br>
            ⑩ 낭만남해는 회원의 부정 거래 행위(현금 융통, 결제수단 도용, 대량 허위거래 등)가 의심되면 일시적으로 서비스 이용을 정지하고 거래 사실 확인 및 소명을 위해 정지한 날로부터 15일 이내에 필요 최소한의 증빙자료(거래 사실 증명서, 물품 수급 및 발주 등)를 요청할 수 있다. 만약 특별한 사정없이 낭만남해가 요청한 증빙자료를 정해진 기한 내에 제출하지 않거나 서류 미비 또는 소명이 되지 않을 경우 서비스 이용이 영구히 정지되거나 이용계약이 해지될 수 있다.<br>
            ⑪ 낭만남해는 회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시하고 있으며, 회원은 낭만남해의 모니터링 업무에 대하여 협조하여야 하며 낭만남해가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num17')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 17조 [서비스 개요 및 종류]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num17' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num17' }"></div>
          <div class="text" v-show="showtext === 'num17'">
            ① 이용자에 대한 통지를 하는 경우 서비스 상에 공지 내지 이용자가 회원가입 당시 제공한 연락처를 이용한 방법으로 이용자에 대해 통지한다.<br>
            ② 불특정 다수에 대한 통지의 경우 서비스 초기 화면에 1주일 이상 해당 내용을 게재함으로써, 개별통지에 갈음할 수 있다.<br>
            ③ 구매자에게 접수한 계약에 대한 변경 내용 및 입점업체와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 연락처로 신속하게 통지한다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num18')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 18조 [서비스의 이용시간]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num18' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num18' }"></div>
          <div class="text" v-show="showtext === 'num18'">
            ① 낭만남해는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다. 다만, 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 그 이용시간을 사전에 회원에게 공지 또는 통지하여야 한다.<br>
            ② 고객센터 운영시간은 특별한 사유가 있는 경우를 제외하고 오전 10시부터 오후 5시까지로 한다. <br>
            ③ 고객센터 미운영시간에는 문의게시판이나 카카오톡채널 등 문의를 접수하며, 영업 개시 후 순차적으로 답변을 처리한다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num19')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 19조 [정보제공 및 광고]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num19' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num19' }"></div>
          <div class="text" v-show="showtext === 'num19'">
            ① 마케팅수신 등의 회원으로부터 수집한 개인정보를 통해 회원의 서비스 이용 중 필요하다고 판단되는 다양한 마케팅 정보를 SMS(LMS), 스마트폰 알림(Push알림), E-mail 등을 활용하여 발송할 수 있다. <br>
            ② 회원은 거래 관련 정보 및 고객 문의 등에 대한 답변을 제외하고 관련 법에 따라 언제든지 마케팅 수신 동의를 철회할 수 있으며, 이 경우 마케팅 정보 등을 제공하는 행위를 중단한다. (단, 시스템 반영에 시차가 있을 수 있음.)<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num20')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 20조 [예약 및 결제]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num20' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num20' }"></div>
          <div class="text" v-show="showtext === 'num20'">
            ① 낭만남해 서비스를 통한 구매자의 예약은 해당 구매자의 입점업체에 대한 청약과 그에 대한 해당 입점업체의 승낙으로 체결된다. 이 때 구매자가 서비스에 등록되지 않은 재화나 용역의 제공을 요청하는 경우 입점업체는 해당 예약에 대한 승인을 거절할 수 있다. <br>
            ② 구매자는 위 ①항에 따라 체결된 예약 건에 대하여 예약 시 서비스를 통해 함께 결제할 수 있다. <br>
            ③ 구매자가 예약이 최종적으로 완료되기 전에 “낭만남해 웹페이지” 내지 “낭만남해 앱”을 종료하는 등의 사용방법을 충분히 숙지하지 못하여 발생하는 예약의 실패에 대해서 낭만남해는 책임지지 않는다. <br>
            ④ 낭만남해에서 구매한 상품에 대한 대금 지급방법은 다음 각 호 중 하나의 방법으로 결제할 수 있다.<br>
            1. 각종 신용카드 결제<br>
            2. 관련 법령에 따라 승인/유통되는 전자금융거래 수단에 의한 결제<br>
            3. 기타 낭만남해가 정하는 결제수단<br>
            ② 구매 대금의 결제와 관련하여 구매자가 입력한 정보 및 그와 관련된 책임은 구매자에게 있으며, 재화 또는 용역의 청약 후 합리적인 일정 기간 내에 결제가 이루어지지 않는 경우 낭만남해는 해당 예약을 취소할 수 있다.<br>
            ③ 낭만남해는 구매자의 결제수단에 대하여 정당한 사용 권한 보유 여부를 확인할 수 있으며 필요한 경우 해당 거래 진행의 정지 및 소명자료의 제출을 요청할 수 있다.<br>
          </div>
        </div>
      </div>
      <div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num21')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 21조 [허위구매 규제]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num21' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num21' }"></div>
          <div class="text" v-show="showtext === 'num21'">
            ① 낭만남해는 선량한 이용자의 보호를 위하여 다음에 해당하는 회원에 대하여 일정한 기간을 정하여 예약 제한 또는 해당 예약 건에 대한 예약 취소 조치를 취할 수 있다.<br>
              1. 부정한 방법을 통한 예약의 경우(예. 매크로 이용 등 비정상적인 방법을 통한 예약)<br>
              2. 반복적인 다량 구매 후 취소하는 경우(예: 재판매를 위하여 일정 기간 동안 다량 구매 후 취소를 반복하는 경우)<br>
              3. 암표 매매의 목적으로 구매하거나 암표 매매를 이미 한 경우(예: 구매한 티켓을 낭만남해의 판매가보다 높은 가격을 받고 타에 판매하는 경우)<br>
              4. 기타 이에 준하는 부정한 이용으로 판단되는 경우<br>
            ② 상기 1항의 경우 낭만남해는 해당 고객에게 그 즉시 7일 이상의 소명 기한을 부여하며, 그 기한 내 고객이 소명서를 제출할 경우 그 내용이 합당하다고 판단되면 해당 조치를 해지할 수 있다.<br>
            ③ 회원이 본 조에 해당하는 행위를 지속적으로 행함으로써 낭만남해 또는 다른 이용자에게 피해를 주는 것으로 판단되는 경우 낭만남해는 회원자격을 제한, 정지 및 상실시킬 수 있다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num22')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 22조 [취소 및 환불]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num22' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num22' }"></div>
          <div class="text" v-show="showtext === 'num22'">
            ① 낭만남해는 구매자로부터 환불 의사표시를 접수하면, 즉시 그러한 사실을 판매자에게 통보한다.<br>
            ② 낭만남해는 구매자의 구매대금 결제확인에 대해 통지한 후 판매 회원이 상당 기간 구매자의 주문 정보를 확인하지 않아 구매자가 거래 취소를 요구하면 낭만남해는 해당 거래를 취소할 수 있으며, 거래가 취소되면 보관 중인 상품 구매대금은 구매자에게 환불된다. 아울러, 낭만남해는 개별적인 정책으로 구매자의 요구 없이도 자동 환불처리 등의 조치를 할 수 있다<br>
            ③ 낭만남해는 판매자가 구매자의 환불에 대한 사실 확인 진행 절차에 적극적으로 임하지 않거나 지연하면 구매자에게서 환불의 원인을 파악한 후 해당 거래를 취소하여 구매자에게 보관 중인 결제 대금을 환불할 수 있다.<br>
            ④ 낭만남해는 구매자의 환불 요청이 정당하지 않다고 판단하면 구매자의 의사표시를 취소하여 보관 중인 결제 대금을 판매자에게 지급할 수 있다. 또한, 구매자의 환불이 접수된 날로부터 7일이 지날 때까지 전화, 이메일(E-mail) 등으로 연락이 되지 않으면 환불의 의사를 철회한 것으로 간주하여 보관 중인 결제 대금을 판매자에게 지급할 수 있다.<br>
            ⑤ 확인된 거래가 취소되어 결제 대금을 환불할 경우는 낭만남해는 거래가 취소된 날로부터 영업일 기준 2~3일 이내에 구매자에게 환불에 필요한 조치를 한다. 신용카드로 결제한 환불은 결제 승인이 취소된다.<br>
            ⑥ 카드 결제로 구매한 건의 환불은 카드 결제 취소를 통해서만 가능하며, 어떠한 경우에도 현금 잔고로 환불되지 않는다.<br>
            ⑦ 예약취소 및 환불처리는 입점업체별 이용안내 또는 별도 서비스 페이지를 통해 명시한 예약취소 및 환불기준에 따른다. <br>
          </div>
        </div>
      </div><div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num23')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 23조 [프로모션(쿠폰 등) 및 이벤트]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num23' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num23' }"></div>
          <div class="text" v-show="showtext === 'num23'">
            ① 서비스를 이용하는 회원에게 프로모션 및 이벤트 등 기타 운영정책에 따라 상품 구매 시 할인 혜택을 받을 수 있는 할인쿠폰 등을 발급할 수 있으며, 할인쿠폰의 종류, 유효기간, 금액, 비율 또는 사용 방법 및 사용조건 등 세부사항은 서비스를 통해 별도로 안내 및 명시한다.<br>
            ② 회원은 할인쿠폰을 본인의 구매에 사용할 수 있는 권한만을 가지며, 어떠한 경우에도 이를 타인에게 실질적으로 매매 또는 양도할 수 없다.<br>
            ③ 쿠폰을 이용하여 결제한 예약거래가 취소될 경우 예약에 이용된 쿠폰은 소멸되어 재사용이 불가하며, 이와 관련한 자세한 사항은 유선 고지 내지 예약서비스 화면을 통해 안내된다.<br>
            ④ 회원탈퇴 및 자격이 상실된 경우, 탈퇴 및 자격 상실일까지 지급된 쿠폰은 자동으로 소멸된다. <br>
            ⑤ 쿠폰은 현금 및 현금화되는 수단으로 환급될 수 없으며, 이용자가 할인쿠폰을 부정한 방법으로 획득 내지 사용하였음이 확인되거나 쿠폰의 이용 방법이 통상적인 방법이 아니라고 판단되는 경우 해당 이용자에 대한 할인쿠폰을 회수 또는 소멸시킬 수 있으며, 해당 이용자의 서비스 이용을 제한할 수 있다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num24')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 24조 [웹사이트의 의무]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num24' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num24' }"></div>
          <div class="text" v-show="showtext === 'num24'">
            ① 낭만남해는 관련 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야 한다.<br>
            ② 서비스 내용의 변경 또는 추가사항이 있는 경우 그 사항을 온라인을 통해 서비스 화면에 공지한다.<br>
            ③ 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안 시스템을 갖추어야 하며 개인정보처리 방침을 공시하고 준수하며, 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력한다.<br>
            ④ 낭만남해는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 그에 필요한 조치를 취하여야 한다.<br>
            ⑤ 낭만남해가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 낭만남해의 고의나 과실에 의해 발생한 경우에 한하여 낭만남해에서 책임을 부담한다.<br>
          </div>
        </div>
      </div><div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num25')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 25조 [이용자의 의무]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num25' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num25' }"></div>
          <div class="text" v-show="showtext === 'num25'">
            ① 회원 및 이용자는 기타 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 낭만남해가 통지하는 사항 등을 준수하여야 하며, 기타 낭만남해의 업무에 방해되는 행위를 하여서는 안된다.<br>
            ② 회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없다.<br>
            ③ 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안된다.<br>
            1. 서비스 신청 변경, 본인인증 등 서비스 이용과정에서 허위 내용을 기재·등록·전송 등을 하는 행위<br>
            2. 다른 회원의 아이디 및 비밀번호를 도용하여 부당하게 서비스를 이용하거나, 정보를 도용하는 행위<br>
            3. 타인의 계좌번호 및 신용카드번호 등 타인의 허락 없이 타인의 결제정보를 이용하여 낭만남해의 유료 서비스를 이용하는 행위<br>
            4. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 행위<br>
            5. 플랫폼에 게시한 정보를 변경하는 행위<br>
            6. 낭만남해와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위<br>
            7. 낭만남해와 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
            8. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에 반하는 정보를 공개 또는 게시하는 행위<br>
            9. 낭만남해의 동의 없이 영리를 목적으로 서비스를 사용하는 행위<br>
            10. 낭만남해의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위<br>
            11. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발하는 컴퓨터 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램 자료를 등록 또는 유포하는 행위<br>
            12. 낭만남해가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공 행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위<br>
            13. 자동 접속 프로그램 등을 사용하는 등 정상적인 사용법과 다른 방법으로 서비스를 이용하여 플랫폼의 서버에 부하를 일으켜 낭만남해의 정상적인 서비스를 방해하는 행위<br>
            14. 다른 회원의 개인정보를 동의 없이 수집, 저장, 공개하는 행위<br>
            ④ 낭만남해는 회원이 제1~3항의 행위를 하는 경우 해당 게시물 등을 삭제 또는 임시 삭제할 수 있으며 서비스의 이용을 제한하거나 해지할 수 있다.<br>
            ⑤ 회원은 회원 정보, 계정 정보에 변경이 있는 경우 이를 즉시 변경하여야 하며, 더불어 비밀번호를 철저히 관리하여야 한다. 회원의 귀책으로 말미암은 관리 소홀, 부정 사용 등에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인이 부담하며, 낭만남해는 이에 대한 어떠한 책임도 부담하지 않는다.<br>
            ⑥ 회원은 낭만남해에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법 배포 등을 할 수 없다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계 기관에 의한 구속 등 법적 조치 등에 관해서는 낭만남해가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 낭만남해에 대하여 손해배상 의무를 진다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num26')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 24조 [게시물 정책]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num26' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num26' }"></div>
          <div class="text" v-show="showtext === 'num26'">
            ① 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 플랫폼은 회원이 게시하거나 등록하는 서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우 또는 제3자로부터의 신고가 접수된 경우 관련 절차에 따라 사전통지 없이 임시조치 및 삭제할 수 있고, 이에 대하여 어떠한 책임도 지지 않는다.<br>
            1. 낭만남해에서 규정한 게시물 원칙에 어긋나거나, 게시물 작성 위치에 부여된 성격에 부합하지 않는 경우<br>
            2. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나거나 광고성 게시물일 경우<br>
            3. 정당한 사유 없이 플랫폼 운영 및 서비스를 방해하는 내용을 기재하는 경우<br>
            4. 저작권, 상표권 등 낭만남해 또는 제3자의 권리를 침해하는 내용일 경우<br>
            5. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글 또는 타인을 비방하는 게시물의 경우<br>
            6. 타인의 정보를 동의 없이 게시물에 노출한 경우<br>
            7. 음란 정보, 선정적 정보, 폭력적이거나 잔인한 정보, 저속한 언어나 욕설, 차별적 표현, 도박과 사행성 정보 기타 사회통념상 허용되지 않는 정보 등이 포함된 게시물의 경우<br>
            8. 사업주 변경 또는 인테리어 공사 등에 따른 권리자(사업주)의 게시물 중단 또는 삭제 요청이 있는 경우<br>
            9. 타인의 계정정보, 성명 등을 무단으로 도용하여 작성한 내용이거나, 타인이 입력한 정보를 무단으로 위변조한 내용인 경우<br>
            10. 기타 관련법령에 위반된다고 판단되는 경우<br>
            ② 본 조 제1항 각 호의 어느 하나에 해당하는 게시물이 확인되는 경우 이용자는 언제든지 고객센터(별도로 마련된 신고절차가 있을 경우 해당 절차)를 통해 신고할 수 있다.<br>
            ③ 회원탈퇴 이후 회원이 작성하였던 게시물 및 댓글 등은 삭제되지 않으며, 회원탈퇴로 인하여 회원 정보가 삭제되기에 작성자 본인을 확인할 수 없어 게시물 편집 및 삭제가 원천적으로 불가하다.<br>
            ④ 낭만남해는 이용자가 서비스 상에 게시한 게시물을 본 서비스의 홍보 및 마케팅의 목적을 위해 별도의 대가지급 없이 사용할 수 있다. <br>
            ⑤ 낭만남해는 서비스 내 게재 이외의 목적으로 게시물을 사용할 경우 게시물에 대한 게시자를 반드시 명시해야 한다. 단, 게시자를 알 수 없는 익명 게시물이나 비영리적인 경우에는 그러하지 아니한다.<br>
            ⑥ 본 조에 따른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 낭만남해가 정한 게시중단요청서비스에 따른다.<br>
          </div>
        </div>
      </div><div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num27')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 27조 [권리의 귀속]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num27' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num27' }"></div>
          <div class="text" v-show="showtext === 'num27'">
            ① 서비스에 대한 저작권 및 지식 재산권은 남해군에 귀속된다. 단, 게시물 및 제휴 계약에 따라 제공된 저작물 등은 제외한다.<br>
            ② 낭만남해에서 제공하는 서비스의 디자인, 텍스트, 스크립트(script), 그래픽, 회원 상호 간 전송 기능 등 낭만남해가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권 기타 지적재산권은 대한민국 법령에 기하여 남해군이 보유하고 남해군에게 소유권 또는 사용권이 있다.<br>
            ③ 회원은 본 이용약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아니라, 플랫폼으로부터 서비스의 이용을 허락받게 되는 바, 서비스는 정보 취득 또는 개인 용도로만 제공되는 형태로 회원이 이용할 수 있다.<br>
            ④ 회원은 명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는 회원 상태 정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여 낭만남해가 만든 텍스트, 스크립트, 그래픽의 회원 상호 간 전송 기능 등을 복사하거나 유통할 수 없다.<br>
            ⑤ 회원은 서비스를 이용하는 과정에서 얻은 정보를 낭만남해의 사전 승낙 없이 복제, 출판, 배포, 방송, 편집, 재가공 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안된다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num28')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 28조 [손해배상]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num28' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num28' }"></div>
          <div class="text" v-show="showtext === 'num28'">
            ① 낭만남해는 서비스의 이용과 관련하여 낭만남해의 귀책사유로 인해 발생하는 이용자의 손해에 대하여 관계법령이 정하는 한도 내에서 배상책임을 진다. 단 이용자의 과실이 있는 경우에는 해당 부분에 대한 낭만남해의 책임이 면제된다.<br>
            ② 회원이 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여 낭만남해가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 낭만남해를 면책시켜야 하며, 낭만남해가 면책되지 못한 경우 당해 회원은 그로 인하여 낭만남해에 발생한 손해를 배상하여야 한다. 다만, 낭만남해의 고의 또는 중과실로 발생한 손해에 대해서는 낭만남해는 민법 등 관계 법령에 따라 책임을 부담한다.<br>
          </div>
        </div>
      </div><div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num29')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 29조 [분쟁해결]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num29' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num29' }"></div>
          <div class="text" v-show="showtext === 'num29'">
            ① 낭만남해는 이용자를 위하여 이용자와 입점업체(판매자) 사이에서 중재 역할을 한다. 또한, 이용자가 제기하는 정당한 의견이나 불만을 반영하고 처리하기 위한 인력 및 설비를 갖추고 운영한다.<br>
            ② 낭만남해는 이용자로부터 제출되는 의견 및 불만사항의 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보해야 한다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num30')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 30조 [재판권 및 준거법]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num30' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num30' }"></div>
          <div class="text" v-show="showtext === 'num30'">
            ① 낭만남해와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 한다.<br>
            다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기한다.<br>
            ② 낭만남해와 이용자 간에 제소된 전자상거래 소송에는 대한민국의 법령을 적용한다.<br>
          </div>
        </div>
      </div><div class="half">
        <div class="content-box">
          <div class="title" @click="toggletext('num31')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 31조 [연결서비스와 피연결서비스 간의 관계]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num31' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num31' }"></div>
          <div class="text" v-show="showtext === 'num31'">
            ① 상위 서비스와 하위 서비스가 링크(예:링크의 대상에는 문자 및 그림 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결서비스(웹사이트)이라 하고 후자를 피연결서비스(웹사이트)라고 한다.<br>
            ② 연결서비스는 피연결서비스가 독자적으로 제공하는 재화/용역에 의하여 발생된 이용자의 거래에 대해서 보증책임을 지지 않는다.<br>
          </div>
        </div>
        <div class="content-box">
          <div class="title" @click="toggletext('num32')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-termsofuse__icon.svg" alt=""></div>
            <p>제 32조 [면책]</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num32' }]"></i>
          </div>
          <div class="text__border" :class="{ 'show-border': showtext === 'num32' }"></div>
          <div class="text" v-show="showtext === 'num32'">
            ① 낭만남해는 입점업체(판매자)와 이용자 간의 상품거래를 중개하는 플랫폼 서비스만을 제공할 뿐 재화를 판매하는 당사자가 아니며, 재화에 대한 정보 및 하자 등에 대한 책임은 입점업체(판매자)에게 있다.<br>
            ② 낭만남해는 입점업체(판매자)가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않는다.<br>
            ③ 낭만남해는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 이에 관한 책임이 면제된다.<br>
            ④ 낭만남해는 이용자의 귀책사유, 사용단말기 오류로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않는다.<br>
            ⑤ 낭만남해는 이용자가 게재한 이용후기 등 정보/자료/사실의 신뢰도, 정확성에 대해서는 책임을 지지 않는다.<br>
            ⑥ 낭만남해는 본 웹사이트/앱을 거치지 않은 입점업체(판매자)와 회원 간 외부 거래에 대하여 책임을 지지 않으며, 회원 상호간 및 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대하여 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없다.<br>
            ⑦ 낭만남해는 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 않는다.<br>
            1. 회원 정보의 허위 또는 부정확성에 기인하는 손해<br>
            2. 서비스에 대한 접속 및 서비스 이용과정에서 이용자의 귀책사유로 발생하는 손해<br>
            3. 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해 및 제3자의 불법적인 행위를 방지하거나 예방하는 과정에서 발생하는 손해<br>
            4. 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해<br>
            ⑧ 낭만남해는 보호자가 동반하지 않은 미성년자의 숙박시설 이용으로 발생하는 숙박시설로부터의 입실거부, 예약취소, 환불불가 등의 불이익에 관하여 책임지지 않는다.<br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showtext: false, // 초기에는 텍스트를 숨김
    };
  },
  methods: {
    toggletext(text) {
      this.showtext = (this.showtext === text) ? null : text;
    },

    goBack() {
      this.$router.go(-1);
    },

  },
};
</script>
